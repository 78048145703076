<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Costeo"
      :subtitle="`| Ver costeo`"
      class="heading-block"
    />
    
    <div>
      <base-block rounded title="Cálculo de las órdenes de producción">
        <div class="block-content font-size-sm pt-0">
          <b-row>
            <b-col sm="12" class="rounded border p-4 mb-2" >
              <h2>1.- Salida a Fabricación / Materia Prima</h2>
              <div class="d-flex flex-row justify-content-start">
                <b-form inline>
                  
                    <label for="importe" class="mr-2">Importe: </label>

                    <b-input-group size="sm" prepend="$">
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        disabled
                        size="sm"
                        :value="numberFormat.format(quoteInfo.production_order.asiento)"
                      ></b-form-input>
                    </b-input-group>
                  
                </b-form>  
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="10" class="rounded border p-4 mb-2" >
              <h2>2.- Mano de obra</h2>
              <div class="d-flex flex-row justify-content-start">
                <table class="table">
                  <thead>
                    <tr>
                      <th width="150px">Número</th>
                      <th width="300px">Nombre</th>
                      <th>Hora de inicio</th>
                      <th>Hora de termino</th>
                      <th>Hora de comida</th>
                      <th>Horas trabajadas</th>
                      <th>Tarifa por horas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(incidencia, index) in quoteInfo.roadmap.history_items" :key="incidencia.id">
                      <td>
                        <select class="form-control" v-model="incidencia.collaborator_id" disabled :index="index">
                          <option>Seleccione</option>
                          <option :value="data.id" v-for="(data, index) in operadoresList" :key="index">
                            {{ data.no_empleado }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" v-model="incidencia.collaborator_id" disabled :index="index">
                          <option>Seleccione</option>
                          <option :value="data.id" v-for="(data, index) in operadoresList" :key="index">
                            {{ data.nombre_completo }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="incidencia.hora_inicio"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="incidencia.hora_final"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="`${incidencia.hora_comida_incio} - ${incidencia.hora_comida_fin}`"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="incidencia.horas_trabajadas"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <div v-for="(data, index) in operadoresList" :key="index" v-show="false">
                          {{ data.id == incidencia.collaborator_id ? incidencia.totalParcialOperador = data.salario_hora * incidencia.horas_trabajadas : 0 }}
                        </div>
                        <b-input-group size="sm" prepend="$">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="numberFormat.format(incidencia.totalParcialOperador)"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5"></td>
                      <td class="text-right">Total:</td>
                      <td>
                        <b-input-group size="sm" prepend="$">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="numberFormat.format(totalOperadores)"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col  class="rounded border p-4 mb-2 ml-2" >
              <h2>&nbsp;</h2>
              <div class="d-flex flex-row justify-content-start">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        Tarifa
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(incidencia, index) in quoteInfo.roadmap.history_items" :key="incidencia.id">
                      <td>
                        <select class="form-control" v-model="incidencia.collaborator_id" disabled :index="index">
                          <option>Seleccione</option>
                          <option :value="data.id" v-for="(data, index) in operadoresList" :key="index">
                            $ {{ numberFormat.format(data.salario_hora) }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="10" class="rounded border p-4 mb-2" >
              <h2>3.- Maquinaria</h2>
              <div class="d-flex flex-row justify-content-start">
                <table class="table">
                  <thead>
                    <tr>
                      <th width="150px">Número</th>
                      <th width="300px">Nombre</th>
                      <th>Hora de inicio</th>
                      <th>Hora de termino</th>
                      <th>Hora de comida</th>
                      <th>Horas trabajadas</th>
                      <th>Tarifa por horas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(incidencia, index) in quoteInfo.roadmap.history_items" :key="incidencia.id">
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="incidencia.machinery_id"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <select class="form-control" v-model="incidencia.machinery_id" disabled :index="index">
                          <option>Seleccione</option>
                          <option :value="data.id" v-for="(data, index) in maquinariasList" :key="index">
                            {{ data.numero_maquina }} - {{ data.nombre_maquina }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="incidencia.hora_inicio"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="incidencia.hora_final"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="`${incidencia.hora_comida_incio} - ${incidencia.hora_comida_fin}`"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group size="sm">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="incidencia.horas_trabajadas"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <div v-for="(data, index) in maquinariasList" :key="index" v-show="false">
                          {{ data.id == incidencia.machinery_id ? incidencia.totalParcialMaquinaria = data.costo_hora * incidencia.horas_trabajadas : 0 }}
                        </div>
                        <b-input-group size="sm" prepend="$">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="numberFormat.format(incidencia.totalParcialMaquinaria)"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5"></td>
                      <td class="text-right">Total:</td>
                      <td>
                        <b-input-group size="sm" prepend="$">
                          <b-form-input
                            class="mb-2 mr-sm-2 mb-sm-0"
                            disabled
                            size="sm"
                            :value="numberFormat.format(totalMaquinaria)"
                          ></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col  class="rounded border p-4 mb-2 ml-2" >
              <h2>&nbsp;</h2>
              <div class="d-flex flex-row justify-content-start">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        Tarifa
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(incidencia, index) in quoteInfo.roadmap.history_items" :key="incidencia.id">
                      <td>
                        <select class="form-control" v-model="incidencia.machinery_id" disabled :index="index">
                          <option>Seleccione</option>
                          <option :value="data.id" v-for="(data, index) in maquinariasList" :key="index">
                            $ {{ numberFormat.format(data.costo_hora) }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" class="rounded border p-4 mb-2" >
              <h2>4.- Totales</h2>
              <b-row>
                <b-col sm="3">
                  <div class="d-flex flex-column justify-content-start">
                    
                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="material" class="mr-2" style="width:100px; justify-content: left;">Material: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format(quoteInfo.production_order.asiento)"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Mano de obra: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format(totalOperadores)"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Gastos indirectos: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format(totalMaquinaria)"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Total en cotización: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format(quoteInfo.total)"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Total facturado: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format(quoteInfo.invoice.subtotal_factura)"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Utilidad: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format( quoteInfo.invoice.subtotal_factura - ( parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria))  )"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Total de la orden: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format( (quoteInfo.invoice.subtotal_factura - ( parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria))) >= 0 ? parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria) + (quoteInfo.invoice.subtotal_factura - ( parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria))) : parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria) )"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>
                    
                  </div>
                </b-col>

                <b-col sm="3">
                  <div class="d-flex flex-column justify-content-start">
                    
                    <!--<div class="col mt-2">
                      <b-form inline>
                        
                          <label for="material" class="mr-2" style="width:100px; justify-content: left;">Kilos producidos: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format(1000)"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>-->

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Piezas producidas: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="piezasProducidas"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>

                    <div class="col mt-2">
                      <b-form inline>
                        
                          <label for="manoObra" class="mr-2" style="width:100px; justify-content: left;">Costo unitario: </label>

                          <b-input-group size="sm" prepend="$">
                            <b-form-input
                              class="mb-2 mr-sm-2 mb-sm-0"
                              disabled
                              size="sm"
                              :value="numberFormat.format( ((quoteInfo.invoice.subtotal_factura - ( parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria))) >= 0 ? parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria) + (quoteInfo.invoice.subtotal_factura - ( parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria))) : parseFloat(quoteInfo.production_order.asiento) + parseFloat(totalOperadores) + parseFloat(totalMaquinaria)) / piezasProducidas )"
                            ></b-form-input>
                          </b-input-group>
                        
                      </b-form>
                    </div>
                    
                  </div>
                </b-col>


              </b-row>
            </b-col>
          </b-row>

        </div>
      </base-block>
    </div>
  </div>
</template>
<script>

  import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion";
  import Maquinarias from "../../../api/admin/maquinaria/Maquinarias";
  import Empleados from "../../../api/admin/empleados/Empleados";
  import Swal from "sweetalert2";

  export default {
    components: {

    },

    data(){
      return {
        quoteId: null,
        quoteInfo:{ roadmap : { history_items: []}, production_order: {}, quote_finished_products:[] },
        maquinariasList:[],
        operadoresList:[],
        numberFormat:new Intl.NumberFormat('es-MX', this.optionsMoneyFormat),
        totalMaquinaria1: 0
      }
    },

    computed: {
      totalMaquinaria: function () {
        let total = 0
        const context = this
        context.quoteInfo.roadmap.history_items.forEach(function (incidencia) {
          context.maquinariasList.forEach(function (maquina) {
            if(incidencia.machinery_id == maquina.id){
              let totalParcial = maquina.costo_hora * incidencia.horas_trabajadas
              total = total + totalParcial
            }
          })
        })
        return total 
      },

      totalOperadores: function () {
        let total = 0
        const context = this
        context.quoteInfo.roadmap.history_items.forEach(function (incidencia) {
          context.operadoresList.forEach(function (operador) {
            if(incidencia.collaborator_id == operador.id){
              let totalParcial = operador.salario_hora * incidencia.horas_trabajadas
              total = total + totalParcial
            }
          })
        })
        return total 
      },

      /*kilosProducidos: function (){
        let total = 0
        const context = this
        context.quoteInfo.roadmap.history_items.forEach(function (incidencia) {
          cosole.log("-")
        })
        return 0
      },*/

      piezasProducidas: function (){
        let total = 0
        const context = this
        context.quoteInfo.quote_finished_products.forEach(function (qfp) {
          total += qfp.cantidad
        })
        return total
      }

      
    },

    methods: {
      async GetQuoteInfo(id){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        PreCotizacion.GetQuot(id,auth)
        .then((response) => {
          const responseQuoteInfo = response.data.data
          context.quoteInfo = responseQuoteInfo
          if(context.quoteInfo.roadmap.history_items.length == 0){
            context.AddIncidencia()
          }
          console.log(context.quoteInfo)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      async AllMaquinas(){
        let auth = JSON.parse(localStorage.autentication);
        Maquinarias.GetAllMaquinas(auth)
        .then((response) => {
          this.maquinariasList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      async AllOperadores(){
        let auth = JSON.parse(localStorage.autentication);
        Empleados.GetAllEmpleados(auth)
          .then((response) => {
            this.operadoresList = response.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      }
    },

    mounted() {
      let me = this;
      me.quoteId = me.$route.params.id
      me.GetQuoteInfo(me.quoteId);
      me.AllMaquinas();
      me.AllOperadores();
    }

  }
</script>

<style scoped>
  .block-title{
    font-size: 100px!important;
  }
  h2{
    font-size:20px!important;
  }
</style>